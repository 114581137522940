import React, { useRef } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';
//styles
import styles from 'components/technologies/technologies.module.css';
//components
import Booking from 'components/feedback';
import { UnderlineBullets, ColumnList } from 'components/bullets/titled-bullets';
import Bullets from 'components/bullets';
import Layout from '../components/layout';
import { EveryPig2, Heypractice3, AreaButler2, MyRedDog2 } from 'components/carousel';
import AiSectors from './ai-sectors';
import AiTechTools from './ai-tech-tools';
import Steps from './steps';
//meta
import identificators from 'components/googleAnalyticsIdentificators';
import { meta } from '../../../metaData';
//assets
import aiServises from './ai-servises.json';

interface Props {
  location: Location;
}

const aiAdoptionList = [
  {
    title: <>Make your product smarter with&nbsp;AI</>,
    text: 'Image recognition, identity verification, speech-to-text, or other – we cover a variety of use cases. We can help you find the most suitable external AI tool and integrate it into your product. Get it up and running in no time!',
  },
  {
    title: 'Prepare your business for AI adoption',
    text: "Ready to explore AI for your business? We'll evaluate your data setup, pinpoint AI opportunities, and conduct a feasibility study to determine if AI implementation is a viable option for your company.",
  },
];
const capabilities = [
  {
    title: 'Computer Vision',
    text: [
      'Image recognition',
      'Visual search',
      'Face recognition',
      'Authentication and identity verification',
      'Optical character recognition (OCR)',
      'Object recognition for IoT',
      'Document understanding',
      'Generative modeling',
    ],
  },
  {
    title: 'Natural Language Processing',
    text: [
      'Speech recognition',
      'Text-to-speech',
      'Customer sentiment analysis',
      'Semantic analysis',
      'Content moderation',
      'Information retrieval',
      'Text summarization',
    ],
  },
  {
    title: 'Predictive Analytics',
    text: [
      'Customer analytics',
      'Credit scoring',
      'Fraud detection',
      'Disease prediction',
      'Demand forecasting',
      'Supply chain optimization',
      'Property valuation',
      'Route optimization',
    ],
  },
];

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function AiDevelopment({ location }: Props) {
  const bookingRef = useRef<HTMLDivElement>(null);
  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout
      title="AI Development & Integration Services"
      subtitle="Integrate existing AI tools into your product. Or hire our AI engineers to prepare your business for AI adoption."
      metaData={{ main: meta.aiDevelopment }}
      pathname={location.pathname}
      customBtns={
        <button
          id={identificators.HEADER_CALL_AI_EXPERTS}
          className={clsx('btn btn_60 btn__white', styles.bookingLink)}
          onClick={handleClickCTA}
        >
          Book a call with our AI experts
        </button>
      }
    >
      <section className="section">
        <div className={clsx('inner-container', styles.containerPaddings)}>
          <h2 className={styles.techTitle}>Putting AI to work</h2>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            We team up with product companies and established businesses to make AI integration
            smooth and to figure out the best way to bring AI into business operations.
          </p>
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <ColumnList
            list={aiAdoptionList}
            titleStyle={styles.listItemPudding}
            listStyle={styles.aiListMargin}
          />
        </div>
      </section>
      <section className="section gray">
        <div className={clsx('inner-container', styles.containerPaddings)}>
          <h2 className={styles.techTitle}>Our AI development services</h2>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            Our services range from implementing AI using off-the-shelf solutions, customizing
            publicly available datasets and pre-trained models, and building custom machine learning
            models. We also provide team augmentation services to help you get the needed AI experts
            for your team.
          </p>
          <Bullets list={aiServises} className={styles.aiBullets} itemStyle={styles.aiBulletItem} />
        </div>
      </section>
      <section className="section">
        <div className={clsx(styles.containerPaddings, styles.aboveSlider)}>
          <h2 className={styles.techTitle}>Explore our recent work</h2>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            Check out some of our projects where we've enhanced our clients' products through AI
            integration.
          </p>
        </div>
      </section>
      <section>
        <SwiperSlider>
          <EveryPig2 />
          <Heypractice3 />
          <AreaButler2 />
          <MyRedDog2 />
        </SwiperSlider>
      </section>
      <section className="section">
        <div className={clsx('inner-container', styles.containerPaddings)}>
          <h2 className={styles.techTitle}>Cross-industry capabilities</h2>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            We have specialized skills and hands-on expertise in computer vision, NLP, and
            predictive analytics that you can apply to various industries.
          </p>
          <UnderlineBullets list={capabilities} listStyle={styles.bulletsListMargin} />
        </div>
      </section>

      <section className="section emphasis">
        <div className={clsx('inner-container', styles.containerPaddings, styles.violetContainer)}>
          <h2 className={clsx(styles.techTitle, styles.whiteText)}>
            We've built AI-powered products for various sectors
          </h2>
          <p className={clsx('subtitle', styles.whiteText)}>
            Over the past few years, we've teamed up with a variety of companies across different
            fields to develop intelligent solutions, leveraging the power of artificial intelligence
            and machine learning.
          </p>
          <AiSectors />
          <button
            className={clsx(styles.redText)}
            onClick={handleClickCTA}
            id={identificators.CALL_WITH_AI_EXPERTS}
          >
            {'Book a call with our \r\n AIs'}&nbsp;experts
          </button>
        </div>
      </section>
      <section className="section gray">
        <div className={clsx('inner-container', styles.containerPaddings)}>
          <h2 className={styles.techTitle}>What we use to develop AI applications</h2>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            We adapt our tech tools for each project, but here are some technologies that we often
            employ.
          </p>
          <AiTechTools />
        </div>
      </section>
      <section className="section">
        <div className="inner-container">
          <h3 className={styles.stepsTitle}>How it works</h3>
          <p className={clsx(styles.subtitleLight, styles.subtitleMargin)}>
            AI development may sound like a complex project, so let's break it down into 4 easy
            steps.
          </p>
          <Steps />
        </div>
      </section>
      <Booking
        title={
          <>
            {'Team up with Brocoders \r\n to adopt'}&nbsp;{'AI'}
          </>
        }
        subtitle="Send us an email or schedule a call with our expert on Calendly to discuss your project and answer all the questions you might have."
        ctaRef={bookingRef}
      />
    </Layout>
  );
}

export default AiDevelopment;
