import * as React from 'react';
//styles
import styles from 'components/technologies/technologies.module.css';
//assets
import Tensorflow from '../../../images/icons/tensorflow_logo.inline.svg';
import Pytorch from '../../../images/icons/pytorch.inline.svg';
import Keras from '../../../images/icons/keras_logo.inline.svg';
import Python from '../../../images/icons/python.inline.svg';
import Julia from '../../../images/icons/julia.inline.svg';
import R from '../../../images/icons/r.inline.svg';
import Pandas from '../../../images/icons/pandas.inline.svg';
import NumPy from '../../../images/icons/numPy_logo.inline.svg';
import OpenCV from '../../../images/icons/openCV_.inline.svg';

import Matplot from '../../../images/icons/matplot.inline.svg';
import Seaborn from '../../../images/icons/seaborn.inline.svg';
import Plotly from '../../../images/icons/plotly.inline.svg';
import Gans from '../../../images/icons/gans.inline.svg';
import Vaes from '../../../images/icons/vaes.inline.svg';
import Autoencoders from '../../../images/icons/autoencoders.inline.svg';
import Lstms from '../../../images/icons/lstms.inline.svg';
import OpenAI from '../../../images/icons/openAI.inline.svg';
import Llams from '../../../images/icons/llams.inline.svg';
import Flask from '../../../images/icons/flask.inline.svg';
import Docker from '../../../images/icons/docker.inline.svg';
import Kubernetes from '../../../images/icons/kubernetes.inline.svg';
import AWS from '../../../images/icons/aws.inline.svg';
import GCP from '../../../images/icons/gcp.inline.svg';
import Azure from '../../../images/icons/azure.inline.svg';

function AiTechTools() {
  return (
    <ul className={styles.tools}>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Machine learning frameworks</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Tensorflow />
          </li>
          <li className={styles.techToolItem}>
            <Pytorch />
          </li>
          <li className={styles.techToolItem}>
            <Keras />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Programming languages</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Python />
          </li>
          <li className={styles.techToolItem}>
            <Julia />
          </li>
          <li className={styles.techToolItem}>
            <R />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Data preprocessing</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Pandas />
          </li>
          <li className={styles.techToolItem}>
            <NumPy />
          </li>
          <li className={styles.techToolItem}>
            <OpenCV />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Visualization</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Matplot />
          </li>
          <li className={styles.techToolItem}>
            <Seaborn />
          </li>
          <li className={styles.techToolItem}>
            <Plotly />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Generative models</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Gans />
          </li>
          <li className={styles.techToolItem}>
            <Vaes />
          </li>
          <li className={styles.techToolItem}>
            <Autoencoders />
          </li>
          <li className={styles.techToolItem}>
            <Lstms />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Large Language Learning Models</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <OpenAI />
          </li>
          <li className={styles.techToolItem}>
            <Llams />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Deployment</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <Flask />
          </li>
          <li className={styles.techToolItem}>
            <Docker />
          </li>
          <li className={styles.techToolItem}>
            <Kubernetes />
          </li>
        </ul>
      </li>
      <li className={styles.techToolGroup}>
        <p className={styles.techToolsName}>Cloud services</p>
        <ul className={styles.techToolList}>
          <li className={styles.techToolItem}>
            <AWS />
          </li>
          <li className={styles.techToolItem}>
            <GCP />
          </li>
          <li className={styles.techToolItem}>
            <Azure />
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default AiTechTools;
