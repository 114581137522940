import * as React from 'react';
//styles
import styles from 'components/technologies/technologies.module.css';
import Line1 from '../../../images/icons/line1.inline.svg';
import Line2 from '../../../images/icons/line2.inline.svg';
import Line3 from '../../../images/icons/line3.inline.svg';
import Line4 from '../../../images/icons/line4.inline.svg';

function Steps() {
  return (
    <ul className={styles.stepList}>
      <li className={styles.stepItem}>
        <p className={styles.stepTitle}>{'Step 1:  \r\nFree consultation'}</p>
        <Line1 />
        <p className={styles.stepText}>
          Share your business needs and goals, and get answers to all your questions.
        </p>
      </li>
      <li className={styles.stepItem}>
        <p className={styles.stepTitle}>{'Step 2:  \r\nSolution design'}</p>
        <Line2 />
        <p className={styles.stepText}>
          Determine use cases and success criteria, select the tech stack, prepare data, build the
          roadmap.
        </p>
      </li>
      <li className={styles.stepItem}>
        <p className={styles.stepTitle}>{'Step 3:  \r\nProof of concept'}</p>
        <Line3 />
        <p className={styles.stepText}>
          Build a mini AI solution, test it, evaluate the results, determine the next steps.
        </p>
      </li>
      <li className={styles.stepItem}>
        <p className={styles.stepTitle}>{'Step 4:  \r\nAI engineering'}</p>
        <Line4 />
        <p className={styles.stepText}>
          Build the application, integrate the ML models, release the solution to a desired
          environment.
        </p>
      </li>
    </ul>
  );
}

export default Steps;
