import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
//styles
import styles from 'components/technologies/technologies.module.css';

const Routes = require('../../routes').Routes;

function AiSectors() {
  const data = useStaticQuery(graphql`
    query {
      advertising: file(relativePath: { eq: "technologies/advertising.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      healthcare: file(relativePath: { eq: "technologies/healthcare.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iot: file(relativePath: { eq: "technologies/iot.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media_entertainment: file(relativePath: { eq: "technologies/media_entertainment.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      real_estate: file(relativePath: { eq: "technologies/real-estate.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      retail: file(relativePath: { eq: "technologies/retail.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      education: file(relativePath: { eq: "technologies/education.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      finance: file(relativePath: { eq: "technologies/finance.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <ul className={styles.imageList}>
      <li className={styles.imageItem}>
        <a href={Routes.FITNESS_MOBILE_SOLUTIONS_B} target="_blank">
          <p className={styles.imageTitle}>Healthcare</p>
          <Img
            fluid={data.healthcare.childImageSharp.fluid}
            className={styles.logo}
            alt="healthcare"
          />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href={Routes.INDUSTRIES_FINTECH} target="_blank">
          <p className={styles.imageTitle}>Finance</p>
          <Img fluid={data.finance.childImageSharp.fluid} className={styles.logo} alt="finance" />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href={Routes.INDUSTRIES_EDTECH} target="_blank">
          <p className={styles.imageTitle}>Education</p>
          <Img
            fluid={data.education.childImageSharp.fluid}
            className={styles.logo}
            alt="education"
          />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href="/" target="_blank">
          <p className={styles.imageTitle}>IoT</p>
          <Img fluid={data.iot.childImageSharp.fluid} className={styles.logo} alt="iot" />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href={Routes.INDUSTRIES_PROPTECH} target="_blank">
          <p className={styles.imageTitle}>Real Estate</p>
          <Img
            fluid={data.real_estate.childImageSharp.fluid}
            className={styles.logo}
            alt="real estate"
          />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href={Routes.INDUSTRIES_EVENTS_MANAGMENT} target="_blank">
          <p className={styles.imageTitle}>Media & Entertainment</p>
          <Img
            fluid={data.media_entertainment.childImageSharp.fluid}
            className={styles.logo}
            alt="media entertainment"
          />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href="/" target="_blank">
          <p className={styles.imageTitle}>Retail</p>
          <Img fluid={data.retail.childImageSharp.fluid} className={styles.logo} alt="retail" />
        </a>
      </li>
      <li className={styles.imageItem}>
        <a href={Routes.INDUSTRIES_MARTECH} target="_blank">
          <p className={styles.imageTitle}>Advertising</p>
          <Img
            fluid={data.advertising.childImageSharp.fluid}
            className={styles.logo}
            alt="advertising"
          />
        </a>
      </li>
    </ul>
  );
}

export default AiSectors;
